import React, { useState } from 'react';

import { isIpValid } from '../../util/validation';
import { apiAddChild } from '../../util/api';
import { withAppContext } from '../withAppContext';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PortalSettings } from '../PortalSettings/portal-settings';
import { useCallback } from 'react';

const Create = props => {
  /**
   * @type {{id: number, name: string, age: number, ip: string }} initialFormState
   */
  const initialFormState = {
    id: null,
    name: '',
    age: null,
    ip: '',
    ageXml: true,
    apiOnTheFly: true,
    portals: [],
  };

  const [item, setItem] = useState(initialFormState);
  const [isValidIp, setIsValidIp] = useState(true);
  const [portals, setPortals] = useState([]);

  /**
   *
   * @param {(import('react').ChangeEvent<HTMLInputElement>|import('react').ChangeEvent<HTMLSelectElement>)} event
   */
  const handleInputChange = event => {
    const { name, value } = event.target;

    // validate ip
    if (name === 'ip') {
      if (value === '') {
        // allow empty ip input
        setIsValidIp(true);
      } else {
        // if not empty validate ip
        setIsValidIp(isIpValid(value));
      }
    }

    if (name === 'safeSearches') {
      if (!Array.isArray(item.safeSearches)) {
        item.safeSearches = [];
      }

      const index = item.safeSearches.indexOf(value);

      if (index > -1) {
        item.safeSearches.splice(index, 1);
      } else {
        item.safeSearches.push(value);
      }
      setItem({ ...item });
    } else if (name === 'apiOnTheFly' || name === 'ageXml') {
      setItem({ ...item, [name]: !item[name] });
    } else {
      setItem({ ...item, [name]: value });
    }
  };

  /**
   *
   * @param {import('react').FormEvent} event
   */
  const handleSubmit = async event => {
    event.preventDefault();

    item.portals = portals;

    if (!item.name || !item.age || (item.ip.length > 1 && !isValidIp)) return;

    props.addItem(await apiAddChild(props.appContext.token, item));
    setItem(initialFormState);
    props.showCreate(false);
  };

  const { formatMessage } = props.intl;

  return (
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <div className="form-group">
        <div className="col-3 col-sm-12">
          <label className="form-label">
            <FormattedMessage id="children.name" />
          </label>
        </div>
        <div className="col-9 col-sm-12">
          <input
            type="text"
            className="form-input"
            placeholder={formatMessage({ id: 'children.name' })}
            name="name"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <br />

      <div className="form-group">
        <div className="col-3 col-sm-12">
          <label className="form-label">
            <FormattedMessage id="children.age" />
          </label>
        </div>
        <div className="col-9 col-sm-12">
          <select className="form-select" name="age" onChange={handleInputChange}>
            <option>{formatMessage({ id: 'children.age_placeholder' })}</option>
            <option value="0">{formatMessage({ id: 'children.ages.0' })}</option>
            <option value="6">{formatMessage({ id: 'children.ages.6' })}</option>
            <option value="12">{formatMessage({ id: 'children.ages.12' })}</option>
            <option value="16">{formatMessage({ id: 'children.ages.16' })}</option>
            <option value="18">{formatMessage({ id: 'children.ages.18' })}</option>
          </select>
        </div>
      </div>

      <br />

      <div className={'form-group' + (isValidIp ? ' has-success' : ' has-error')}>
        <div className="col-3 col-sm-12">
          <label className="form-label">
            <FormattedMessage id="children.ip" />
          </label>
        </div>
        <div className="col-9 col-sm-12">
          <input
            type="text"
            className="form-input"
            placeholder={formatMessage({ id: 'children.ip' })}
            name="ip"
            onChange={handleInputChange}
          />
          <p className="form-input-hint">
            {!isValidIp && (
              <>
                <FormattedMessage id="children.ip_invalid" />
              </>
            )}
          </p>
        </div>
      </div>

      <div className="form-group">
        <div className="col-3 col-sm-12">
          <label className="form-label">
            <FormattedMessage id="settings.use_age_xml" />
          </label>
        </div>
        <div className="col-9 col-sm-12">
          <input
            type="checkbox"
            defaultChecked={item.ageXml}
            name="ageXml"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="form-group">
        <div className="col-3 col-sm-12">
          <label className="form-label">
            <FormattedMessage id="settings.use_otf" />
          </label>
        </div>
        <div className="col-9 col-sm-12">
          <input
            type="checkbox"
            defaultChecked={item.apiOnTheFly}
            name="apiOnTheFly"
            onChange={handleInputChange}
          />
        </div>
      </div>

      <br />
      <div className="form-group">
        {props.portals.length > 0 && (
          <>
            <br />
            <h3 style={{ width: '100%' }}>Online-Portale</h3>
            {props.portals.map((portal, i) => {
              return (
                <PortalSettings
                  key={portal.name}
                  name={portal.name}
                  active={
                    portals.length &&
                    portals.find(({ name }) => name === portal.name) &&
                    portals.find(({ name }) => name === portal.name).active
                  }
                  safeSearch={
                    portals.length &&
                    portals.find(({ name }) => name === portal.name) &&
                    portals.find(({ name }) => name === portal.name).safeSearch
                  }
                  age={parseInt(portal.age)}
                  globalAge={parseInt(item.age)}
                  hasSafeSearch={portal.safeSearches.length > 0}
                  text={portal.text}
                  disabled={false}
                  onActiveToggle={() => {
                    const newPortals = portals ? [...portals] : [];
                    const oldPortal = newPortals.find(({ name }) => portal.name === name);
                    const newPortal = {
                      name: portal.name,
                      active: oldPortal ? !oldPortal.active : true,
                      safeSearch: oldPortal ? oldPortal.safeSearch : false,
                      text: portal.text,
                    };
                    setPortals([
                      ...newPortals.filter(({ name }) => name !== newPortal.name),
                      newPortal,
                    ]);
                  }}
                  onSafeSearchToggle={(auto = false) => {
                    const newPortals = portals ? [...portals] : [];
                    const oldPortal = newPortals.find(({ name }) => portal.name === name);
                    const newPortal = {
                      name: portal.name,
                      active: oldPortal && auto ? !oldPortal.safeSearch : true,
                      safeSearch: oldPortal ? !oldPortal.safeSearch : true,
                      text: portal.text,
                    };
                    setPortals([
                      ...newPortals.filter(({ name }) => name !== newPortal.name),
                      newPortal,
                    ]);
                  }}
                />
              );
            })}
          </>
        )}
      </div>

      <br />

      <div className="form-group">
        <div className="col-3 col-sm-12">
          <button className="btn btn-primary input-group-btn" type="submit">
            <i className="icon icon-plus" /> <FormattedMessage id="children.add" />
          </button>
        </div>
        <div className="col-9 col-sm-12">
          <button
            type="reset"
            className="btn btn-danger input-group-btn"
            onClick={() => props.showCreate(false)}
          >
            <i className="icon icon-cross" /> <FormattedMessage id="children.abort" />
          </button>
        </div>
      </div>
    </form>
  );
};

const CreateChild = withAppContext(injectIntl(Create));

export { CreateChild };
