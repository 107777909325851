import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';

export const PortalSettings = props => {
  const {
    name,
    active,
    safeSearch,
    hasSafeSearch,
    text,
    disabled,
    onActiveToggle,
    onSafeSearchToggle,
    age,
    globalAge,
  } = props;

  const [man, setMan] = useState(false);

  if (
    !man &&
    !isNaN(globalAge) &&
    age &&
    globalAge &&
    ((age <= globalAge && !active) || (age > globalAge && active))
  ) {
    onSafeSearchToggle(true);
  }

  return (
    <div>
      <h5>{name}</h5>

      <label>
        <input
          type="checkbox"
          checked={active}
          disabled={disabled}
          onChange={() => {
            setMan(true);
            onActiveToggle();
          }}
        />{' '}
        <FormattedMessage id="portals.portals_allowed" />
      </label>
      {hasSafeSearch && (
        <>
          <span style={{ marginRight: '30px' }} />
          <label>
            <input
              type="checkbox"
              checked={safeSearch}
              disabled={disabled}
              onChange={() => {
                setMan(true);
                onSafeSearchToggle();
              }}
            />{' '}
            <FormattedMessage id="portals.portals_use_safe_search" />
          </label>
        </>
      )}

      <p>{text}</p>
    </div>
  );
};
