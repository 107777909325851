import React, { useState, useEffect } from 'react';

// import { FormattedMessage } from 'react-intl';

import { isIpValid } from '../../util/validation';
import { Input } from '../Form/Input';
import { IpInput } from '../Form/IpInput';
import { PortalSettings } from '../PortalSettings/portal-settings';

const Item = props => {
  const [item, setItem] = useState({ ...props.item, newip: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [isValidIp, setIsValidIp] = useState(true);
  const [portals, setPortals] = useState(item.portals);

  useEffect(() => {
    setItem(props.item);
  }, [props]);
  /**
   *
   * @param {import('react').ChangeEvent<HTMLInputElement>} event
   */
  const handleCheckedChange = event => {
    const { name } = event.target;
    setItem({ ...item, [name]: !item[name] });
  };

  /**
   *
   * @param {import('react').FormEvent} event
   */
  const handleSubmit = event => {
    event.preventDefault();

    console.log(item.portals);

    if (!item.name || !item.age || !isValidIp) return;

    setIsEditing(false);

    props.updateItem(item.id, {...item, portals: portals});
  };

  /**
   *
   * @param {import('react').ChangeEvent<HTMLInputElement>|import('react').ChangeEvent<HTMLSelectElement>} event
   */
  const handleInputChange = event => {
    const { name, value } = event.target;

    // validate ip
    if (name === 'ip') {
      if (value === '') {
        // allow empty ip input
        setIsValidIp(true);
      } else {
        // if not empty validate ip
        setIsValidIp(isIpValid(value));
      }
    }

    if (name === 'safeSearches') {
      if (!Array.isArray(item.safeSearches)) {
        item.safeSearches = [];
      }

      const index = item.safeSearches.indexOf(value);

      if (index > -1) {
        item.safeSearches.splice(index, 1);
      } else {
        item.safeSearches.push(value);
      }
      setItem({ ...item });
    } else {
      setItem({ ...item, [name]: value });
    }
  };

  const staticIps = item.ips.filter(({ dyndns }) => !dyndns);
  const dynamicIps = item.ips.filter(({ dyndns }) => dyndns);

  return (
    <>
      {isEditing ? (
        <tr>
          <td colSpan={5}>
            <form method="post" onSubmit={handleSubmit}>
              <div className={'form-group' + (isValidIp ? ' has-success' : ' has-error')}>
                <Input
                  className="form-input"
                  label="Name"
                  type="text"
                  name="name"
                  value={item.name}
                  handleChange={handleInputChange}
                />
                <div className="form-group">
                  <label className="form-label">Alter</label>
                  <select
                    className="form-select"
                    value={item.age}
                    name="age"
                    onChange={handleInputChange}
                  >
                    <option value="0">ab 0</option>
                    <option value="6">ab 6</option>
                    <option value="12">ab 12</option>
                    <option value="16">ab 16</option>
                    <option value="16">ab 18</option>
                  </select>
                  <br />
                </div>
                <div className="form-group">
                  <label>IP Adressen</label>
                  <Input
                  className="form-input"
                  label="IP"
                  type="text"
                  name="ip"
                  value={item.ip}
                  handleChange={handleInputChange}
                />
                  {Boolean(staticIps.length) &&
                    staticIps.map((ip, index) => (
                      <IpInput
                        key={ip.id}
                        label="IP Adressen"
                        index={index}
                        className="form-input"
                        isEditing={isEditing}
                        ip={ip}
                        handleChange={handleInputChange}
                      />
                    ))}
                </div>
                <Input
                  className="form-input"
                  name="newip"
                  value={item.newip}
                  label="Neue IP Adresse"
                  handleChange={handleInputChange}
                />
                {dynamicIps.length > 0 && (
                  <div className="form-group">
                    <label className="form-label">Nicht änderbare IP Adressen</label>
                    {dynamicIps.map((ip, index) => {
                      return (
                        <span
                          className="tooltip"
                          data-tooltip="Dynamische IP\nnicht änderbar"
                          key={`nonedit-${ip.id}`}
                          style={{
                            fontStyle: 'italic',
                            opacity: 0.7,
                            cursor: 'default',
                          }}
                        >
                          {ip.ip}
                          {dynamicIps.length > index + 1 ? ', ' : ''}
                        </span>
                      );
                    })}
                  </div>
                )}
                <div className="form-group">
                  <div className="col-9 col-sm-12">
                    <label className="form-label">Age XML benutzen?</label>
                  </div>
                  <div className="col-9 col-sm-12">
                    <input
                      type="checkbox"
                      defaultChecked={item.ageXml}
                      name="ageXml"
                      onChange={handleCheckedChange}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-9 col-sm-12">
                    <label className="form-label">On-The-Fly Ergebnisse beachten?</label>
                  </div>
                  <div className="col-9 col-sm-12">
                    <input
                      type="checkbox"
                      defaultChecked={item.apiOnTheFly}
                      name="apiOnTheFly"
                      onChange={handleCheckedChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  {props.portals.length > 0 && (
                    <>
                      <br />
                      <h3>Online-Portale</h3>
                      {props.availablePortals &&
                        props.availablePortals.map((portal, i) => {
                          return (
                            <PortalSettings
                              key={portal.name}
                              name={portal.name}
                              active={portals.find(p => p.name === portal.name).active}
                              safeSearch={portals.find(p => p.name === portal.name).safeSearch}
                              age={parseInt(portal.age)}
                              globalAge={parseInt(props.age)}
                              hasSafeSearch={portal.safeSearches.length > 0}
                              text={portal.text}
                              disabled={false}
                              onActiveToggle={() => {
                                const newPortals = portals ? [...portals] : [];
                                const oldPortal = newPortals.find(
                                  ({ name }) => portal.name === name,
                                );
                                const newPortal = {
                                  name: portal.name,
                                  active: oldPortal ? !oldPortal.active : true,
                                  safeSearch: oldPortal ? oldPortal.safeSearch : false,
                                  text: portal.text,
                                };
                                setPortals([...newPortals.filter(({name}) => name !== newPortal.name), newPortal]);
                              }}
                              onSafeSearchToggle={() => {
                                const newPortals = portals ? [...portals] : [];
                                const oldPortal = newPortals.find(
                                  ({ name }) => portal.name === name,
                                );
                                const newPortal = {
                                  name: portal.name,
                                  active: oldPortal ? oldPortal.active : true,
                                  safeSearch: oldPortal ? !oldPortal.safeSearch : false,
                                  text: portal.text,
                                };
                                setPortals([...newPortals.filter(({name}) => name !== newPortal.name), newPortal]);
                              }}
                            />
                          );
                        })}
                    </>
                  )}
                </div>
                <button className="btn btn-primary">
                  <i className="icon icon-check" />
                </button>
              </div>
            </form>
          </td>
        </tr>
      ) : (
        <tr>
          <td>
            <input type="checkbox" name={item.id} id={item.id} onChange={handleCheckedChange} />
          </td>
          <td>{item.name}</td>
          <td>ab&nbsp;{item.age}</td>
          <td>
            {item.ip},
            {Boolean(item.ips) &&
              item.ips.map((ip, index) => (
                <span
                  className={ip.dyndns ? 'tooltip' : undefined}
                  data-tooltip={ip.dyndns ? 'Dynamische IP\nnicht änderbar' : undefined}
                  key={`nonedit-${ip.id}`}
                  style={{
                    fontStyle: ip.dyndns ? 'italic' : 'initial',
                    opacity: ip.dyndns ? 0.7 : 1,
                    cursor: 'default',
                  }}
                >
                  {ip.ip}
                  {item.ips.length > index + 1 ? ', ' : ''}
                </span>
              ))}
          </td>
          <td>
            {Boolean(item.portals) &&
              item.portals.map((portal, index) => (
                <span key={`nonedit-${portal.name}`}>
                  {portal.active && portal.name}
                  {item.safeSearches.length > index + 1 ? ' ' : ''}
                </span>
              ))}
          </td>
          <td style={{ textAlign: `right` }}>
            {Boolean(props.updateItem)?<button
              className="btn btn-default btn-sm mr-2"
              title="editieren"
              onClick={() => setIsEditing(true)}
            >
              <i className="icon icon-edit" />
            </button>:null}
            {Boolean(props.deleteItem)?<button
              className="btn btn-error btn-sm"
              title="löschen"
              onClick={() => window.confirm('Sind Sie sicher?') && props.deleteItem(item.id)}
            >
              <i className="icon icon-delete" />
            </button>:null}
          </td>
        </tr>
      )}
    </>
  );
};

export { Item };
