import React from 'react';
import { Input } from './Input';
import { useState } from 'react';

const IpInput = ({ handleChange, ip, index, isEditing }) => {
  const [curIp, setIp] = useState(ip.ip);

  const handleIpChange = event => {
    setIp(event.target.value);
    handleChange(event);
  };

  return (
    <Input
      type="text"
      name={`ip[${ip.id}]`}
      placeholder=" "
      value={curIp}
      handleChange={handleIpChange}
      disabled={!isEditing}
    />
  );
};

export { IpInput };
