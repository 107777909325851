import React from 'react';

import { Item } from './Item';
import { FormattedMessage } from 'react-intl';

const ChildTable = props => (
  <>
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th>#</th>
          <th>
            <FormattedMessage id="children.name" />
          </th>
          <th>
            <FormattedMessage id="children.age" />
          </th>
          <th>IP</th>
          <th>Online Portale</th>
          <th style={{ textAlign: `right` }}>
            <FormattedMessage id="children.actions" />
          </th>
        </tr>
      </thead>
      <tbody>
        {props.items && props.items.length > 0
          ? props.items.map(item => (
              <Item
                item={item}
                key={item.id}
                updateItem={props.updateItem}
                deleteItem={props.deleteItem}
                availableSafeSearches={props.availableSafeSearches}
                allSafeSearches={props.allSafeSearches}
                portals={item.portals}
                availablePortals={props.availablePortals}
                setPortals={props.setPortals}
              />
            ))
          : null}
      </tbody>
    </table>
  </>
);

export { ChildTable };
