import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ChildTable } from '../../components/ChildTable/Index';
import { CreateChild } from '../../components/ChildTable/Create-Child';
import withLayout from '../../layout';
import {
  apiFetchChildren,
  apiRemoveChild,
  apiUpdateChild,
  apiSafeSearchList,
} from '../../util/api';
import { adminApiPortalList } from '../../util/adminApi';
import { getUser } from '../../util/auth';
// import children from '../../i18n/translations/en/children';
import { AccessibilityMenu } from '../../components/AccessibilityMenu/AccessibilityMenu';

const ChildrenPage = props => {
  // Setting state
  const [items, setItems] = useState({ childrenData: [] });
  const [showCreate, setShowCreate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [availablePortals, setAvailablePortals] = useState([]);

  // get data from api
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      // get token from localStorage
      const user = getUser();
      const token = user && user.token;
      const result = await apiFetchChildren(token);
      setItems(result);

      const portals = await adminApiPortalList();
      setAvailablePortals(portals);

      setIsLoading(false);
    };
    
    
    fetchData();
  }, []);
  
  // CRUD operations
  const addItem = item => {
    setItems([...items, item]);
  };
  
  const deleteItem = async id => {
    await apiRemoveChild(getUser().token, id);
    setItems(items.filter(item => item.id !== id));
  };

  const updateItem = async (id, updatedItem) => {
    const result = await apiUpdateChild(getUser().token, updatedItem);
    setItems(items.map(item => (item.id === result.id ? result : item)));
  };

  const { formatMessage, locale } = props.intl;

  return (
    <>
    <AccessibilityMenu i18nKey="children" />
      <div
        dangerouslySetInnerHTML={{
          __html: formatMessage(
            {
              id: 'children.info',
            },
            {
              locale: locale,
            },
          ),
        }}
      />
      <hr />
      <br />
      {showCreate ? (
        <CreateChild
          showCreate={setShowCreate}
          addItem={addItem}
          portals={availablePortals}
        />
      ) : (
        <button className="btn" onClick={() => setShowCreate(true)}>
          Kind hinzufügen
        </button>
      )}
      <br />
      <br />
      <ChildTable
        items={items}
        updateItem={updateItem}
        deleteItem={deleteItem}
        isLoading={isLoading}
        availablePortals={availablePortals}
      />
    </>
  );
};

const customProps = {
  localeKey: 'list',
  withAuth: true,
  title: <FormattedMessage id="children.title" />,
};

export default withLayout(customProps)(injectIntl(ChildrenPage));
